/**
 * 示例-自定义组件-城市选择
 * luxinwen
 * 2023-04
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-city 城市选择</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-city v-model="value"></sp-city>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-city v-model="value"&gt;&lt;/sp-city&gt;</pre>
          <p>基本用法。可以使用 v-model 双向绑定数据。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-city v-model="value" suffix></sp-city>
          <Divider orientation="left" plain>显示后缀</Divider>
          <pre>&lt;sp-city v-model="value" suffix&gt;&lt;/sp-city&gt;</pre>
          <p>设置属性 <span class="text-red">suffix</span>，可以返回城市后缀。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-city v-model="value" border class="width-s"></sp-city>
          <Divider orientation="left" plain>显示边框</Divider>
          <pre>&lt;sp-city v-model="value" border&gt;&lt;/sp-city&gt;</pre>
          <p>设置属性 <span class="text-red">border</span>，可以显示边框。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-city v-model="value" border disabled class="width-s"></sp-city>
          <Divider orientation="left" plain>禁用状态</Divider>
          <pre>&lt;sp-city v-model="value" border disabled&gt;&lt;/sp-city&gt;</pre>
          <p>设置属性 <span class="text-red">disabled</span>，可以禁用组件。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '指定选中的城市 value 值，可以使用 v-model 双向绑定数据',
            type: 'String',
            default: ''
          },
          'suffix': {
            tips: '是否返回城市后缀，如“广州市”、“香港特别行政区”',
            type: 'Boolean',
            default: 'false'
          },
          'disabled': {
            tips: '是否禁用',
            type: 'Boolean',
            default: 'false'
          },
          'border': {
            tips: '是否显示边框',
            type: 'Boolean',
            default: 'false'
          },
          'clearable': {
            tips: '是否可以清空选项',
            type: 'Boolean',
            default: 'true'
          },
          'placeholder': {
            tips: '默认显示文字',
            type: 'String',
            default: '请选择'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        value: ''
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      }
    }
  };
</script>